import React from 'react';
import styled from 'styled-components';
import { Theming } from '../../../theming';

type FreeTextInputProps = React.TextareaHTMLAttributes<HTMLTextAreaElement> & {
    onChange?: (newValue: string) => void;
};

export const FreeTextInput = ({ onChange = () => {}, ...props }: FreeTextInputProps) => {
    return <StyledTextArea onChange={e => onChange(e.target.value)} />;
};

const StyledTextArea = styled.textarea`
    display: block;
    width: 100%;
    height: 7em;
    border: ${Theming.iconColorDisabled} solid 1px;
    border-radius: 5px;
    resize: none;
`;
