import React, { useContext, useEffect, useState } from 'react';
import { InfoText, PageTitle } from 'shared/components/Text';
import { UserContext } from '../context/UserContext';
import { UserRole } from 'shared/types/User';
import { BaseButton } from 'shared/components/Buttons';
import { useHistory } from 'react-router-dom';
import { AuthRouteNames } from '../navigation/routeNames';
import { AvatarPair } from 'shared/components/AvatarPair';
import LoadingSpinner from 'shared/components/Loader';
import { useAsync } from 'shared/hooks/useAsync';
import { userApiManager } from '../network/apiManager';
import styled from 'styled-components';
import IconManager, { IconType } from 'shared/components/IconManager';
import { Theming } from '../theming';
import { Modal } from 'shared/components/Modal';

export const Intermediate = () => {
    const history = useHistory();
    const { currentLogin, surveyStatus } = useContext(UserContext);

    const [partnerCompleted, setPartnerCompleted] = useState<boolean | undefined>(undefined);
    const [showWarningModal, setShowWarningModal] = useState<boolean>(false);

    const partnerRole = currentLogin?.userRole === UserRole.PATIENT ? 'caregiver' : 'patient';

    const patientCompleted = (currentLogin?.userRole === UserRole.PATIENT && surveyStatus?.surveyCompleted) ||
        (currentLogin?.userRole === UserRole.CARE_PARTNER && partnerCompleted);
    const caregiverCompleted = (currentLogin?.userRole === UserRole.PATIENT && partnerCompleted) ||
        (currentLogin?.userRole === UserRole.CARE_PARTNER && surveyStatus?.surveyCompleted);

    const patientDisplayName = currentLogin?.userRole === UserRole.PATIENT ? (currentLogin.userFirstName ?? 'Patient') : (currentLogin?.pairFirstName ?? 'Patient');
    const caregiverDisplayName = currentLogin?.userRole === UserRole.CARE_PARTNER ? (currentLogin.userFirstName ?? 'Caregiver') : (currentLogin?.pairFirstName ?? 'Caregiver');

    //get user's partner's survey completion status on load
    useEffect(() => {
        getPartnerSurveyStatus.execute();
    }, [])

    const onLoad = async () => {
        try {
            //fetch survey completion dates for all users
            const resultDates = await userApiManager.Results.getDates({});

            //find date for current user's partner and set boolean to state
            if (currentLogin) {
                const partnerCompletedDate = resultDates[currentLogin.pairUsername]?.completionDates[0];
                setPartnerCompleted(!!partnerCompletedDate);
            }
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    const onContinue = () => {
        if (partnerCompleted) {
            history.push(AuthRouteNames.Summary);
        } else {
            setShowWarningModal(true);
        }
    }

    const getPartnerSurveyStatus = useAsync(onLoad)

    return (
        <>
            {!!currentLogin && (!!surveyStatus && partnerCompleted !== undefined) ?
                <>
                    {!partnerCompleted ?
                        <IconWrapper>
                            <IconManager type={IconType.CIRCLE_CROSS} stroke={Theming.logoutButtonColor} strokeWidth={2} size={150} />
                        </IconWrapper>
                    :
                        <IconWrapper>
                            <IconManager type={IconType.CHAT_BOXES} stroke={Theming.primaryIconColor} fill={Theming.secondaryIconColor} strokeWidth={1} size={150} />
                        </IconWrapper>
                    }

                    <PageTitle text={'Thank you, your information has been saved.'} margin={'20px auto'} />

                    {!partnerCompleted ?
                        <InfoText
                            text={`Please stop here. It looks like your ${partnerRole} has not completed their survey. Before you move on to the next page, please ask them to complete their survey so you can choose your top priorities together.`}
                            margin={'25px 10px'}
                        />
                    :
                        <InfoText
                            text={`Please stop here. It looks like your ${partnerRole} has completed their survey. Please connect with them before moving to the next page where you’ll discuss your priorities together.`}
                            margin={'25px 10px'}
                        />
                    }

                    <AvatarPair
                        primaryLabel={`${patientDisplayName}<br/>${patientCompleted ? 'Completed' : 'Not Completed'}`}
                        secondaryLabel={`${caregiverDisplayName}<br/>${caregiverCompleted ? 'Completed' : 'Not Completed'}`}
                        primaryActive={patientCompleted}
                        secondaryActive={caregiverCompleted}
                    />
                    <BaseButton
                        text={'Continue'}
                        onClick={onContinue}
                        margin={'20px auto'}
                    />
                    <Modal
                        isOpen={showWarningModal}
                        hide={() => setShowWarningModal(false)}showTitle={false}
                        content={
                            <>
                                <InfoText bold text={`Your ${partnerRole} has not yet completed the survey.`} />
                                <InfoText text={'Please stop here and ask them to complete it so you can choose top priorities together.'} />
                            </>
                        }
                        confirmButtonText={'Continue Anyway'}
                        cancelButtonText={'Go Back'}
                        onConfirm={() => {
                            setShowWarningModal(false);
                            history.push(AuthRouteNames.Summary);
                        }}
                    />
                </>
                :
                <LoadingSpinner />
            }
        </>
    )
}

const IconWrapper = styled.div`
  padding: 0px;
  text-align: center;
`;