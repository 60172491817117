import { OrderedQuestionSchema, QuestionType } from 'shared/types/Question';
import { Importance, OrderedSurveySchema } from 'shared/types/Survey';
import _ from 'lodash';
import { OrderedCategorySchema } from 'shared/types/Category';
import { CreateResultsRequest } from 'shared/types/api/results/CreateResults';

export interface SurveyResponse {
    [key: string]: {
        questionId: string;
        answer: string | Importance;
    };
}

//filter out empty survey sections
export const filterSurveyQuestions = (survey: OrderedSurveySchema) => {
    const filteredSurveySections = survey.sections.filter(section => {
        return section.categories.length;
    });
    return { ...survey, sections: filteredSurveySections };
};

//convert questions to format needed for storing/updating responses
export const adaptQuestionsToResponses = (survey: OrderedSurveySchema): SurveyResponse[] => {
    //reformat categories into array in order by section
    const categoriesBySection = survey.sections.reduce(
        (allCategories: OrderedCategorySchema[][], section, index): OrderedCategorySchema[][] => {
            return [...allCategories, section.categories];
        },
        []
    );

    //extract questions from categories, still in order by section
    const questionsBySection = categoriesBySection.reduce(
        (
            allSections: Array<OrderedQuestionSchema[]>,
            currentSection,
            index
        ): Array<OrderedQuestionSchema[]> => {
            const categoryQuestions = currentSection.reduce(
                (questions: OrderedQuestionSchema[], category) => {
                    return [...questions, ...category.questions];
                },
                []
            );
            return [...allSections, categoryQuestions];
        },
        []
    );

    //format questions into responses
    const formattedResponsesBySection = questionsBySection.map(section => {
        return section.reduce((responsesBySection: SurveyResponse, question): SurveyResponse => {
            const response = {
                [question.id]: {
                    questionId: question.id,
                    answer: question.questionType === QuestionType.FREE_TEXT ? '' : Importance.NOT_IMPORTANT,
                },
            };
            return { ...responsesBySection, ...response };
        }, {});
    });

    return formattedResponsesBySection;
};

//format each response and combine into single array
export const adaptResponsesToApi = (surveyResponses: SurveyResponse[]): CreateResultsRequest => {
    const responseRequest: CreateResultsRequest = [];

    surveyResponses.forEach(responseSection => {
        _.forIn(responseSection, (response, key) => {
            responseRequest.push({
                questionId: response.questionId,
                answer: response.answer,
            });
        });
    });

    return responseRequest;
};
