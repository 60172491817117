import React, { createContext, Dispatch, ReactNode, SetStateAction, useEffect, useState } from 'react';
import { OrderedSurveySchema } from 'shared/types/Survey';
import { adaptQuestionsToResponses, SurveyResponse } from '../adapters/surveyAdapters';

type SurveyContextType = {
    surveyQuestions: OrderedSurveySchema | null;
    setSurveyQuestions: Dispatch<SetStateAction<OrderedSurveySchema | null>>;
    surveyResponses: SurveyResponse[] | null;
    setSurveyResponses: Dispatch<SetStateAction<SurveyResponse[] | null>>;
    currentSection: number | null;
    setCurrentSection: Dispatch<SetStateAction<number | null>>;
    isLastSection: boolean;
    // setIsLastSection: Dispatch<SetStateAction<boolean>>;
    clearSurvey: () => void;
};

export const SurveyContext = createContext<SurveyContextType>({
    surveyQuestions: null, //questions for display
    setSurveyQuestions: () => {},
    surveyResponses: null, //questions reformatted to store and update responses
    setSurveyResponses: () => {},
    currentSection: null, //pagination for survey sections
    setCurrentSection: () => {},
    isLastSection: false, //for survey submission logic
    // setIsLastSection: () => {},
    clearSurvey: () => {},
});

export default function SurveyContextProvider({ children }: { children: ReactNode }) {
    const [surveyQuestions, setSurveyQuestions] = useState<OrderedSurveySchema | null>(null);
    const [surveyResponses, setSurveyResponses] = useState<SurveyResponse[] | null>(null);
    const [currentSection, setCurrentSection] = useState<number | null>(null);
    const [isLastSection, setIsLastSection] = useState<boolean>(false);

    useEffect(() => {
        //when survey questions get fetched, create survey responses and set survey to first page
        if (surveyQuestions) {
            setCurrentSection(0);
            setSurveyResponses(adaptQuestionsToResponses(surveyQuestions));
        }
    }, [surveyQuestions]);

    useEffect(() => {
        if (currentSection !== null && surveyQuestions) {
            //update isLastSection when navigating to (and back from) last survey section
            const lastSection = surveyQuestions.sections.length - 1;
            if (currentSection === lastSection && !isLastSection) {
                setIsLastSection(true);
            } else if (currentSection !== lastSection && isLastSection) {
                setIsLastSection(false);
            }
        }
    }, [currentSection]);

    const clearSurvey = () => {
        setSurveyResponses(null);
        setSurveyQuestions(null);
        setCurrentSection(null);
    };

    return (
        <SurveyContext.Provider
            value={{
                surveyQuestions,
                setSurveyQuestions,
                surveyResponses,
                setSurveyResponses,
                currentSection,
                setCurrentSection,
                isLastSection,
                clearSurvey,
            }}
        >
            {children}
        </SurveyContext.Provider>
    );
}
