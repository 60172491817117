import styled from 'styled-components';
import { Theming } from '../../../theming';

export const SectionIndicatorWrapper = styled.div`
    display: flex;
    flex-directon: row;
    justify-content: center;
    margin: 5px auto;
    padding: 10px 20px;
`;

export const SectionIndicator = styled.div<{ active: boolean }>`
    display: flex;
    flex: 1;
    background-color: ${({ active }) => (active ? Theming.secondaryButtonColor : Theming.iconColorDisabled)};
    border-radius: 5px;
    height: 1em;
    margin: 5px;
`;
