import React from 'react';
import { OrderedQuestionSchema } from 'shared/types/Question';
import { UserRole } from 'shared/types/User';
import { SurveyOption } from './SurveyOption';
import { AccordionSection } from '../../../../../shared/components/AccordionSection';

type SurveyCategoryProps = {
    title: string;
    questions: OrderedQuestionSchema[];
    userRole: UserRole;
    useHeader?: boolean;
};

export const SurveyCategory = ({ title, questions, userRole, useHeader = true }: SurveyCategoryProps) => {
    return (
        <>
            {useHeader ? (
                <AccordionSection title={title}>
                    {questions.map((question, index) => {
                        return (
                            <SurveyOption
                                key={index}
                                type={question.questionType}
                                text={question.description[userRole] ?? ''}
                                questionId={question.id}
                            />
                        );
                    })}
                </AccordionSection>
            ) : (
                <>
                    {questions.map((question, index) => {
                        return (
                            <SurveyOption
                                key={index}
                                type={question.questionType}
                                text={question.description[userRole] ?? ''}
                                questionId={question.id}
                            />
                        );
                    })}
                </>
            )}
        </>
    );
};
