export enum Environments {
    INTERNAL = 'internal',
    CLIENT = 'client',
    PROD = 'prod',
}

export const environmentConfig: {
    apiUrl: string;
} = (() => {
    switch (process.env.STAGE as Environments) {
        case Environments.INTERNAL:
            return {
                apiUrl: 'https://api-internal.pacestalk.com',
            };
        case Environments.CLIENT:
            return {
                apiUrl: 'https://api-client.pacestalk.com',
            };
        case Environments.PROD:
            return {
                apiUrl: 'https://api.pacestalk.com',
            };
    }
})();

export const isProd = process.env.STAGE === Environments.PROD;