import React, { useState } from 'react';
import { PageTitle, StyledInfoText } from 'shared/components/Text';
import { contentStrings } from '../../../constants/contentStrings';
import { MailToLink } from 'shared/components/Links';
import { UnlabeledTextInput } from 'shared/components/Input';
import { BaseButton } from 'shared/components/Buttons';
import styled from 'styled-components';
import { removeWhiteSpace } from 'shared/utils/removeWhiteSpace';

type LoginFormProps = {
    onSubmit: (login: { username: string; password: string }) => void;
};

type FormState = {
    username: string;
    password: string;
    error: FormError | null;
};

enum FormError {
    MISSING_FIELDS = 'Please fill out all fields.',
    INVALID_PASSWORD_LENGTH = 'Your password must be at least 8 characters.'
}

export const LoginForm = ({ onSubmit }: LoginFormProps) => {
    //email address for users to contact for help
    const pacesHelpEmail = 'pacestalk@gmail.com'

    const initialFormState = {
        username: '',
        password: '',
        error: null
    };
    const [formState, setFormState] = useState<FormState>(initialFormState);

    const validateForm = () => {
        //clear any prior errors
        setFormState(prevState => {
            return { ...prevState, error: null };
        });

        //set form to valid if username & password, else set form errors
        if (!formState.username || !formState.password) {
            setFormState(prevState => {
                return { ...prevState, error: FormError.MISSING_FIELDS };
            });
        } else if (formState.password.length < 8) {
            setFormState(prevState => {
                return { ...prevState, error: FormError.INVALID_PASSWORD_LENGTH };
            });
        } else {
            onSubmit({
                username: formState.username,
                password: formState.password,
            });
        }
    };

    return (
        <LoginFormContainer>
            <PageTitle text={contentStrings.login.title} />
            <StyledInfoText>
                {contentStrings.login.info}
                <MailToLink path={pacesHelpEmail} />
            </StyledInfoText>
            <UnlabeledTextInput
                value={formState.username}
                onChange={e => {
                    //saving value to variable to prevent synthetic event error
                    const newValue = removeWhiteSpace(e.target.value);
                    setFormState(prevState => {
                        return { ...prevState, username: newValue };
                    });
                }}
                placeholder={'User ID'}
                type={'text'}
                required
                error={formState.error === FormError.MISSING_FIELDS && !formState.username ? formState.error : ''}
            />
            <UnlabeledTextInput
                value={formState.password}
                onChange={e => {
                    //saving value to variable to prevent synthetic event error
                    const newValue = removeWhiteSpace(e.target.value);
                    setFormState(prevState => {
                        return { ...prevState, password: newValue };
                    });
                }}
                placeholder={'Password'}
                type={'password'}
                required
                error={(formState.error === FormError.MISSING_FIELDS && !formState.password)
                    || (formState.error === FormError.INVALID_PASSWORD_LENGTH && formState.password.length < 8) ? formState.error : ''}
            />
            <BaseButton text={'Sign In'} onClick={validateForm} />
        </LoginFormContainer>
    );
};

const LoginFormContainer = styled.div`
    padding: 20px 0;
`;
