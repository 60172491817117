// export enum UrlParams {
//     pageNumber = ':pageNumber',
// }

export const NonAuthRouteNames = {
    Login: '/',
    CreateAccount: '/register',
    ResetPassword: '/reset',
    Unauthorized: '/unauthorized',
};

export const AuthRouteNames = {
    Tutorial: `/tutorial`,
    Survey: '/survey',
    Summary: '/summary',
    Complete: '/complete',
    RedirectToStart: '/',
    Onboarding: '/onboarding',
    Profile: '/profile',
    Intermediate: '/submitted',
    NotFound: '/404'
};
