import React, { useContext, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Importance } from 'shared/types/Result';
import { ImportanceOptions } from '../../../components/ImportanceOptions';
import { Theming } from '../../../theming';
import { QuestionType } from 'shared/types/Question';
import { FreeTextInput } from './FreeTextInput';
import { SurveyContext } from '../../../context/SurveyContext';
import LoadingSpinner from 'shared/components/Loader';

type SurveyOptionProps = {
    text: string;
    type: QuestionType;
    questionId: string;
};

export type SurveyOptionValue = {
    questionId: string;
    answer: Importance | string;
};

export const SurveyOption = ({ text, type, questionId }: SurveyOptionProps) => {
    const { surveyResponses, setSurveyResponses, currentSection } = useContext(SurveyContext);

    const didMountRef = useRef(false); //to prevent updates to parent component state on initial render

    const [surveyOptionValue, setSurveyOptionValue] = useState<SurveyOptionValue | null>(null);

    useEffect(() => {
        //set option on initial load
        if (!!surveyResponses && currentSection !== null) {
            setSurveyOptionValue(surveyResponses[currentSection][questionId]);
        }
    }, []);

    //if value has changed, update survey response in context
    useEffect(() => {
        if (didMountRef.current) {
            setSurveyResponses(prevState => {
                if (currentSection !== null && surveyOptionValue) {
                    const updatedState = [...prevState];
                    updatedState[currentSection][surveyOptionValue.questionId] = surveyOptionValue;
                    return updatedState;
                } else {
                    return prevState;
                }
            });
        } else {
            didMountRef.current = true;
        }
    }, [surveyOptionValue]);

    //set value when option changes
    const onAnswerChange = newValue => {
        setSurveyOptionValue(prevState => {
            return prevState !== null ? { ...prevState, answer: newValue } : prevState;
        });
    };

    return (
        <>
            {surveyOptionValue ? (
                <SurveyOptionContainer>
                    <SurveyBullet />
                    <SurveyContentWrapper>
                        <SurveyQuestion>{text}</SurveyQuestion>
                        {/*show importance icons or text input based on type of question */}
                        {type === QuestionType.IMPORTANCE ? (
                            <ImportanceOptions
                                initialValue={surveyOptionValue.answer as Importance} //question is of type importance
                                onChange={onAnswerChange}
                            />
                        ) : (
                            <FreeTextInput onChange={onAnswerChange} />
                        )}
                    </SurveyContentWrapper>
                </SurveyOptionContainer>
            ) : (
                <LoadingSpinner />
            )}
        </>
    );
};

const SurveyOptionContainer = styled.div`
    display: flex;
    margin: 0 0 0 20px;
`;

const SurveyCheckbox = styled.input`
    margin: 10px auto;
    height: 20px;
    width: 20px;
    display: inline-block;
`;

const SurveyBullet = styled.div`
    margin-top: 16px;
    margin-left: 20px;
    height: 4px;
    width: 6px;
    padding: 1px;
    display: inline-block;
    background-color: ${Theming.primaryIconColor};
    border-radius: 50%;
    @media (max-width: ${Theming.breakpoints.mobile}px) {
        margin: 16px auto;
        max-height: 2px;
        max-width: 4px;
        padding: 2px;
    }
`;

const SurveyContentWrapper = styled.div`
    flex: 1;
    padding: 10px;
    text-align: left;
`;
    // margin-left: 20px;

export const SurveyQuestion = styled.p`
    display: inline-block;
    font-size: 18px;
    text-align: left;
    font-family: ${Theming.regularFont};
    color: ${Theming.primaryTextColor};
    margin: 0 auto;
    padding-bottom: 20px;
    padding-left: 5px;
`;
