import React, { useState } from 'react';
import { Modal, ModalProps } from 'shared/components/Modal';
import { useAsync } from 'shared/hooks/useAsync';
import { LabeledTextInput } from 'shared/components/Input';
import { ErrorMessage, InfoText } from 'shared/components/Text';
import { userApiManager } from '../../network/apiManager';
import styled from 'styled-components';

type FormState = {
    oldPassword: string
    newPassword: string
    newPasswordConfirm: string
    error: FormError | null
    success: boolean
}

enum FormError {
    EMPTY_FIELDS = "Please fill out all fields.",
    INVALID_LENGTH = "Password must be at least 8 characters.",
    MISMATCHED_PASSWORDS = "Password fields must match."
}

export const ChangePassword = ({ isOpen, hide, onConfirm }: ModalProps) => {
    const initialFormState = {
        oldPassword: '',
        newPassword: '',
        newPasswordConfirm: '',
        error: null,
        success: false
    }

    const [formState, setFormState] = useState<FormState>(initialFormState);

    const onSubmit = async () => {
        try {
            //send request
            await userApiManager.Auth.changePassword({
                oldPassword: formState.oldPassword,
                newPassword: formState.newPassword
            })
            //show success message
            setFormState({...initialFormState, success: true})
        } catch(error) {
            console.log(error);
            throw error;
        }
    }

    const validateForm = () => {
        //clear errors
        setFormState(prevState => {return {...prevState, error: null}});

        //check that all fields are filled out
        if (!formState.oldPassword && !formState.newPassword && !formState.newPasswordConfirm) {
            setFormState(prevState => {
                return { ...prevState, error: FormError.EMPTY_FIELDS }
            });
        //check that new password meets requirements
        } else if (formState.newPassword.length < 8) {
            setFormState(prevState => {
                return { ...prevState, error: FormError.INVALID_LENGTH }
            });
        //check that passwords match
        } else if (formState.newPassword !== formState.newPasswordConfirm) {
            setFormState(prevState => {
                return {...prevState, error: FormError.MISMATCHED_PASSWORDS}
            });
        } else {
            //if valid, submit request
            submitPasswordReset.execute();
        }
    }

    const getFieldError = (field) => {
        return (formState.error === FormError.EMPTY_FIELDS && !formState[field]) ||
        (formState.error === FormError.INVALID_LENGTH && formState[field].length < 8) ||
        (formState.error === FormError.MISMATCHED_PASSWORDS && formState.newPassword !== formState.newPasswordConfirm && field === 'newPasswordConfirm') ? formState.error : '';
    }

    const submitPasswordReset = useAsync<void>(onSubmit, "Error submitting your request. Please verify your password and try again.");

    return (
        <Modal
            isOpen={isOpen}
            hide={() => {
                //clear errors if any
                if (submitPasswordReset.error) {
                    submitPasswordReset.clearError();
                }
                //clear form and close modal
                setFormState(initialFormState);
                hide();
            }}
            onConfirm={!formState.success ? validateForm : () => {
                //clear form and close modal
                setFormState(initialFormState)
                onConfirm();
            }}
            showIcon={false}
            title={!formState.success ? 'Reset Password?' : 'Success'}
            confirmButtonText={!formState.success ? 'Confirm' : 'Okay'}
            cancelButtonText={'Cancel'}
            hideCancel={formState.success}
            content={
                <>
                    {!formState.success ?
                        <FormWrapper>
                            <LabeledTextInput
                                label={'Old Password: '}
                                textAlign={'left'}
                                labelWidth={'100%'}
                                placeholder={''}
                                value={formState.oldPassword}
                                width={'100%'}
                                onChange={e => {
                                    //saving value to variable to prevent synthetic event error
                                    const newValue = e.target.value;
                                    setFormState(prevState => {
                                        return { ...prevState, oldPassword: newValue };
                                    });
                                }}
                                required
                                type={'password'}
                                error={getFieldError('oldPassword')}
                            />
                            <LabeledTextInput
                                label={'New Password: '}
                                textAlign={'left'}
                                labelWidth={'100%'}
                                placeholder={''}
                                value={formState.newPassword}
                                width={'100%'}
                                onChange={e => {
                                    //saving value to variable to prevent synthetic event error
                                    const newValue = e.target.value;
                                    setFormState(prevState => {
                                        return { ...prevState, newPassword: newValue };
                                    });
                                }}
                                required
                                type={'password'}
                                error={getFieldError('newPassword')}
                            />
                            <LabeledTextInput
                                label={'Confirm Password'}
                                textAlign={'left'}
                                labelWidth={'100%'}
                                placeholder={''}
                                value={formState.newPasswordConfirm}
                                width={'100%'}
                                onChange={e => {
                                    //saving value to variable to prevent synthetic event error
                                    const newValue = e.target.value;
                                    setFormState(prevState => {
                                        return { ...prevState, newPasswordConfirm: newValue };
                                    });
                                }}
                                required
                                type={'password'}
                                error={getFieldError('newPasswordConfirm')}
                            />
                            {submitPasswordReset.error && <ErrorMessage text={submitPasswordReset.error} margin={'25px auto'} />}
                        </FormWrapper>
                        :
                        <InfoText text={'You have successfully reset your password.'} />
                    }
                </>
            }
        />
    )
}

const FormWrapper = styled.div`
  margin: 20px auto;
  padding: 0 20px;
`;