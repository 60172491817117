import styled from 'styled-components';
import { sharedTheming } from 'shared/sharedTheming';

export const MainContainer = styled.div`
    margin: 0 auto;
    padding: 10px 0 30px;
    width: 40%;
    @media (max-width: ${sharedTheming.breakpoints.largeScreen}px) {
        width: 50%;
    }
    @media (max-width: ${sharedTheming.breakpoints.mediumScreen}px) {
        width: 60%;
    }
    @media (max-width: ${sharedTheming.breakpoints.smallScreen}px) {
        width: 70%;
    }
    @media (max-width: ${sharedTheming.breakpoints.mobile}px) {
        width: 87%;
    }
    font-family: ${sharedTheming.regularFont};
`;
