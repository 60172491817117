import { UserRole } from 'shared/types/User';

export const modalContent = {
    warnings: {
        survey: {
            title: 'Continue without selections?',
            text: 'You have not selected any items. Are you sure you wish to continue?',
        },
    },
    errors: {
        title: 'Please select importance or fill out text field for all checked questions.',
    },
    confirmations: {
        summary: {
            title: 'Do you wish to continue?',
            text: {
                [UserRole.PATIENT]:
                    'This will complete your survey, saving your answers and sharing them with your caregiver.',
                [UserRole.CARE_PARTNER]:
                    'This will complete your survey, saving your answers and sharing them with your patient.',
            },
        },
    },
};
