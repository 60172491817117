import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { RFC } from 'shared/types/sharedTypes';
import { ErrorMessage, InfoText, PageTitle } from 'shared/components/Text';
import { AvatarPair } from 'shared/components/AvatarPair';
import { UserRole } from 'shared/types/User';
import { contentStrings } from '../../constants/contentStrings';
import { AccordionSection } from '../../../../shared/components/AccordionSection';
import { BaseButton } from 'shared/components/Buttons';
import { useAsync } from 'shared/hooks/useAsync';
import { AuthRouteNames } from '../../navigation/routeNames';
import { Modal } from 'shared/components/Modal';
import { AccordionWrapper, ButtonWrapper } from 'shared/components/Wrappers';
import { SummaryResult } from './components/SummaryResult';
import IconManager, { IconType } from 'shared/components/IconManager';
import { modalContent } from '../../constants/modalContent';
import { Theming } from '../../theming';
import {
    adaptResultsToApi,
    adaptResultsToForm,
    RankedPairResults,
    RankedResult,
    ResultType,
} from '../../adapters/summaryAdapters';
import { UserContext } from '../../context/UserContext';
import LoadingSpinner from 'shared/components/Loader';
import { userApiManager } from '../../network/apiManager';
import { FormPrompt } from 'shared/components/FormPrompt';
import { getImportanceString } from 'shared/constants/importanceStrings';
import { Importance } from 'shared/types/Result';

type TogglePriorityProps = {
    result: RankedResult;
    currentCollection: ResultType;
    isTopPriority?: boolean;
};

export const Summary: RFC = () => {
    const { currentLogin } = useContext(UserContext);
    const userRole = currentLogin?.userRole;
    const history = useHistory();

    const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
    const [rankedResults, setRankedResults] = useState<RankedPairResults | null>(null);
    const [isEditing, setIsEditing] = useState<boolean>(true);

    useEffect(() => {
        //fetch data on load
        getResults.execute();
    }, []);

    const getResultsAsync = async () => {
        try {
            const resultsData = await userApiManager.Results.getResults({});

            if (resultsData) {
                setRankedResults(adaptResultsToForm(resultsData));
            }
        } catch (error) {
            console.log(error);
            throw error;
        }
    };

    const togglePriority = ({ result, currentCollection, isTopPriority }: TogglePriorityProps) => {
        setRankedResults(prevState => {
            //results can't be null
            if (prevState) {
                if (!isTopPriority) {
                    //remove item from current collection
                    const updatedCurrentCollection = prevState[currentCollection].filter(
                        item => item.id !== result.id
                    );

                    //add item to top priority collection
                    const updatedNewCollection = [...prevState[ResultType.TOP_PRIORITY], result];

                    //set updated state
                    return {
                        ...prevState,
                        [ResultType.TOP_PRIORITY]: updatedNewCollection,
                        [currentCollection]: updatedCurrentCollection,
                    };
                } else {
                    //remove item from top priority collection
                    const updatedCurrentCollection = prevState[currentCollection].filter(
                        item => item.id !== result.id
                    );

                    //add item to collection matching combined importance rating of pair
                    const updatedNewCollection = [...prevState[result.priorityType], result];
                    //set updated state
                    return {
                        ...prevState,
                        [ResultType.TOP_PRIORITY]: updatedCurrentCollection,
                        [result.priorityType]: updatedNewCollection,
                    };
                }
            } else {
                return prevState;
            }
        });
    };

    const onRetake = () => {
        //navigate to start of survey
        history.push(AuthRouteNames.Survey);
    };

    //TODO: figure out component unmount error
    const onSubmit = async () => {
        //clear any errors
        saveResults.clearError();
        try {
            if (rankedResults) {
                //adapt data to format for request
                const updateResultsRequest = adaptResultsToApi(rankedResults);
                //submit changes to server
                await userApiManager.Results.updateResults(updateResultsRequest);
                if (!saveResults.error && isEditing) {
                    setIsEditing(false);
                }
            }
        } catch (err) {
            console.log(err);
            throw err;
        }
        //navigate to next page
        history.push(AuthRouteNames.Complete);
    };

    const getResults = useAsync(getResultsAsync, 'No results found.');
    const saveResults = useAsync(onSubmit, 'Error saving your changes. Please check your connection and try again.');

    return (
        <>
            {!!currentLogin && !!userRole && !!rankedResults ? (
                <>
                    <PageTitle text={'Summary'} />
                    <AvatarPair
                        userRole={userRole}
                        primaryLabel={userRole === UserRole.PATIENT ? currentLogin.userFirstName : currentLogin.pairFirstName}
                        secondaryLabel={userRole === UserRole.CARE_PARTNER ? currentLogin.userFirstName : currentLogin.pairFirstName}
                    />
                    <InfoText text={contentStrings.summary.info[userRole]} />
                    <AccordionWrapper>
                        <AccordionSection title={'Top Priorities'} showSection>
                            {rankedResults[ResultType.TOP_PRIORITY].map((result, index, array) => {
                                return (
                                    <SummaryResult
                                        key={index}
                                        question={result.text[userRole] ?? ''}
                                        questionType={result.questionType}
                                        userRole={userRole}
                                        response={result.answer}
                                        priorityType={result.priorityType}
                                        iconType={IconType.STAR_FILL}
                                        onClick={() =>
                                            togglePriority({
                                                result: result,
                                                currentCollection: ResultType.TOP_PRIORITY,
                                                isTopPriority: true,
                                            })
                                        }
                                    />
                                );
                            })}
                        </AccordionSection>
                        <AccordionSection title={'Important to Both'} showSection>
                            {rankedResults[ResultType.IMPORTANT_TO_BOTH].map((result, index) => {
                                return (
                                    <SummaryResult
                                        key={index}
                                        question={result.text[userRole] ?? ''}
                                        userRole={userRole}
                                        response={result.answer}
                                        priorityType={result.priorityType}
                                        onClick={() =>
                                            togglePriority({
                                                result: result,
                                                currentCollection: ResultType.IMPORTANT_TO_BOTH,
                                            })
                                        }
                                    />
                                );
                            })}
                        </AccordionSection>
                        <AccordionSection title={'Important to Patient'} showSection>
                            {rankedResults[ResultType.IMPORTANT_TO_PATIENT].map((result, index) => {
                                return (
                                    <SummaryResult
                                        key={index}
                                        question={result.text[userRole] ?? ''}
                                        userRole={userRole}
                                        response={result.answer}
                                        priorityType={result.priorityType}
                                        onClick={() =>
                                            togglePriority({
                                                result: result,
                                                currentCollection: ResultType.IMPORTANT_TO_PATIENT,
                                            })
                                        }
                                    />
                                );
                            })}
                        </AccordionSection>
                        <AccordionSection title={'Important to Caregiver'} showSection>
                            {rankedResults[ResultType.IMPORTANT_TO_CAREGIVER].map((result, index) => {
                                return (
                                    <SummaryResult
                                        key={index}
                                        question={result.text[userRole] ?? ''}
                                        userRole={userRole}
                                        response={result.answer}
                                        priorityType={result.priorityType}
                                        onClick={() =>
                                            togglePriority({
                                                result: result,
                                                currentCollection: ResultType.IMPORTANT_TO_CAREGIVER,
                                            })
                                        }
                                    />
                                );
                            })}
                        </AccordionSection>
                        <AccordionSection
                            title={
                                userRole === UserRole.PATIENT
                                    ? 'My Other Questions'
                                    : "Patient's Other Questions"
                            }
                            showSection
                        >
                            {rankedResults[ResultType.NON_RANKED_PATIENT].map((result, index) => {
                                return (
                                    <SummaryResult
                                        key={index}
                                        questionType={result.questionType}
                                        question={result.text[userRole] ?? ''}
                                        userRole={userRole}
                                        response={result.answer}
                                        priorityType={result.priorityType}
                                        onClick={() =>
                                            togglePriority({
                                                result: result,
                                                currentCollection: ResultType.NON_RANKED_PATIENT,
                                            })
                                        }
                                    />
                                );
                            })}
                        </AccordionSection>
                        <AccordionSection
                            title={
                                userRole === UserRole.CARE_PARTNER
                                    ? 'My Other Questions'
                                    : "Caregiver's Other Questions"
                            }
                            showSection
                        >
                            {rankedResults[ResultType.NON_RANKED_PARTNER].map((result, index) => {
                                return (
                                    <SummaryResult
                                        key={index}
                                        questionType={result.questionType}
                                        question={result.text[userRole] ?? ''}
                                        userRole={userRole}
                                        response={result.answer}
                                        priorityType={result.priorityType}
                                        onClick={() =>
                                            togglePriority({
                                                result: result,
                                                currentCollection: ResultType.NON_RANKED_PARTNER,
                                            })
                                        }
                                    />
                                );
                            })}
                        </AccordionSection>
                        <AccordionSection title={getImportanceString(Importance.NOT_IMPORTANT)}>
                            {rankedResults[ResultType.NOT_IMPORTANT].map((result, index) => {
                                return (
                                    <SummaryResult
                                        key={index}
                                        question={result.text[userRole] ?? ''}
                                        userRole={userRole}
                                        response={result.answer}
                                        priorityType={result.priorityType}
                                        onClick={() =>
                                            togglePriority({
                                                result: result,
                                                currentCollection: ResultType.NOT_IMPORTANT,
                                            })
                                        }
                                        disableRanking
                                    />
                                );
                            })}
                        </AccordionSection>
                    </AccordionWrapper>

                    <ButtonWrapper>
                        {/*{userRole === UserRole.PATIENT ? (*/}
                            <BaseButton text={'SAVE'} onClick={() => setShowConfirmationModal(true)} />
                        {/*) : (*/}
                        {/*    <BaseButton*/}
                        {/*        text={'Finish'}*/}
                        {/*        onClick={() => history.push(AuthRouteNames.Complete)}*/}
                        {/*    />*/}
                        {/*)}*/}
                        <BaseButton text={'Retake Full Survey'} isPrimary={false} onClick={onRetake} />
                    </ButtonWrapper>

                    <Modal
                        title={modalContent.confirmations.summary.title}
                        text={modalContent.confirmations.summary.text[userRole]}
                        icon={
                            <IconManager
                                type={IconType.CHECK_CIRCLE}
                                stroke={Theming.modalIconColorSecondary}
                                size={85}
                                strokeWidth={1.5}
                            />
                        }
                        cancelButtonText={'Go Back'}
                        isOpen={showConfirmationModal}
                        hide={() => setShowConfirmationModal(false)}
                        onConfirm={saveResults.execute}
                    />

                    {/*warns before browser refresh/back button*/}
                    <FormPrompt when={isEditing} />
                </>
            ) : (
                <>{!!getResults.error ? <ErrorMessage text={getResults.error} /> : <LoadingSpinner />}</>
            )}
        </>
    );
};
