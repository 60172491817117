export const sharedTheming = {
    primaryBackgroundColor: '#F8F8F8',
    menuBackgroundColor: '#FFF',

    //icons
    primaryIconColor: '#10589A',
    secondaryIconColor: '#00A70D',
    tertiaryIconColor: '#FDD03B',
    iconColorDisabled: '#BBBBBB',

    //text
    primaryTextColor: '#333',
    secondaryTextColor: '#707070',
    placeholderTextColor: '#858D91',
    titleTextColor: '#707070',
    subTitleTextColor: '#10598A',

    //buttons
    primaryButtonColor: '#10589A',
    secondaryButtonColor: '#58AFE2',
    buttonTextColor: '#FFF',
    logoutLinkColor: '#58AFE2',
    logoutButtonColor: '#C0392B',

    errorColor: '#BF1919',

    linkColor: '#58AFE2',

    separatorColor: '#BBBBBB',

    //modals
    modalIconColorPrimary: '#FDD03B',
    modalIconColorSecondary: '#10589A',
    modalButtonTextPrimary: '#10589A',
    modalButtonTextSecondary: '#E51414',
    modalBackgroundColor: '#FFF',
    modalBorderColor: '#C9C9C9',
    modalButtonColor: 'transparent',

    regularFont: 'Trebuchet MS, sans-serif',

    //mobile responsive
    breakpoints: {
        smallScreen: 768,
        mobile: 480,
        mediumScreen: 1024,
        largeScreen: 1280,
    },
};
