import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { InfoText, PageTitle } from 'shared/components/Text';
import { InputLabel, InputWrapper, LabeledTextInput } from 'shared/components/Input';
import { UserContext } from '../../context/UserContext';
import LoadingSpinner, { MiniLoadingSpinner } from 'shared/components/Loader';
import { BaseButton } from 'shared/components/Buttons';
import { UserRole } from 'shared/types/User';
import styled from 'styled-components';
import { useAsync } from 'shared/hooks/useAsync';
import { userApiManager } from '../../network/apiManager';
import { BackToLink } from 'shared/components/Links';
import { ChangePassword } from './ChangePassword';

type FormState = {
    firstName: string
    isEditing: boolean
}

export const Profile = () => {
    const history = useHistory();
    const { currentLogin, setCurrentLogin, setUserLoading } = useContext(UserContext);
    const initialFormState = {
        firstName: currentLogin?.userFirstName ?? '',
        isEditing: false
    }
    const [formState, setFormState] = useState<FormState>(initialFormState)
    const [showResetPassword, setShowResetPassword] = useState<boolean>(false);

    useEffect(() => {
        //when current user gets updated, update form data
        setFormState(initialFormState);
    }, [currentLogin])

    const onSubmit = async () => {
        try {
            //prevent unauthorized screen while user updates
            setUserLoading(true);
            await userApiManager.Users.updateCurrentUser({ firstName: formState.firstName }, setCurrentLogin);
        } catch(error) {
            console.log(error);
            throw error;
        }
    }

    const saveUserData = useAsync<void>(onSubmit, 'Error saving your data. Please check your connection and try again.')

    return (
        <>
            <LinkWrapper>
                <BackToLink
                    path={''}
                    text={'back to previous'}
                    onClick={() => history.goBack()}
                />
            </LinkWrapper>
            {!!currentLogin ?
                <>
                    <PageTitle text={'Profile'} margin={'20px auto'} />
                    <FormWrapper>
                        <LabeledTextInput
                            label={'First Name: '}
                            placeholder={formState.firstName}
                            value={formState.firstName}
                            width={'auto'}
                            disabled={!formState.isEditing || saveUserData.pending}
                            onChange={(e) => {
                                const newVal = e.target.value;
                                setFormState(prevState => {
                                    return {...prevState, firstName: newVal}
                                });
                            }}
                        />
                        <InputWrapper>
                            <InputLabel>Username:</InputLabel>
                            <InfoText
                                text={currentLogin.username}
                                inline
                                margin={'0 0 0 15px'}
                            />
                        </InputWrapper>
                        <InputWrapper>
                            <InputLabel>Role:</InputLabel>
                            <InfoText
                                text={currentLogin.userRole === UserRole.PATIENT ? 'Patient' : 'Caregiver'}
                                inline
                                margin={'0 0 0 15px'}
                            />
                        </InputWrapper>
                    </FormWrapper>
                    <BaseButton
                        text={!formState.isEditing ? 'Edit Profile' : !saveUserData.pending ? 'Save' : <MiniLoadingSpinner />}
                        isPrimary={false}
                        onClick={() => {
                            if (formState.isEditing) {
                                saveUserData.execute();
                            } else {
                                setFormState(prevState => {
                                    return {...prevState, isEditing: true}
                                })
                            }
                        }}
                    />
                    <BaseButton
                        text={'Reset Password'}
                        onClick={() => setShowResetPassword(true)}
                    />

                    <ChangePassword isOpen={showResetPassword} hide={() => setShowResetPassword(false)} onConfirm={() => setShowResetPassword(false)} />
                </>
            :
                <LoadingSpinner />
            }
        </>
    )
}

const FormWrapper = styled.div`
    margin: 20px auto;
`;

const LinkWrapper = styled.div`
  padding: 5px 0 10px;
`;