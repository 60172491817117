import React from 'react';
import { RFC } from 'shared/types/sharedTypes';
import { CreateAccountForm } from 'shared/components/CreateAccountForm';
import { userApiManager } from '../../network/apiManager';
import { AuthRouteNames, NonAuthRouteNames } from '../../navigation/routeNames';
import { UserContext } from '../../context/UserContext';
import styled from 'styled-components';

export const CreateAccount: RFC = () => {
    return (
        <FormContainer>
            <CreateAccountForm
                apiManager={userApiManager}
                initialPath={NonAuthRouteNames.CreateAccount}
                redirectPath={AuthRouteNames.RedirectToStart}
                userContext={UserContext}
            />
        </FormContainer>
    )
}

const FormContainer = styled.div`
  margin: 20px auto;
`;