import React, { useContext } from 'react';
import { AuthRouteNames } from '../navigation/routeNames';
import { RFC } from 'shared/types/sharedTypes';
import { Redirect } from 'react-router-dom';
import { UserContext } from '../context/UserContext';
import LoadingSpinner from 'shared/components/Loader';

export const RedirectToStart: RFC = () => {
    const { currentLogin, surveyStatus } = useContext(UserContext);

    //if user has not completed survey, direct to onboarding, if survey but no top priorities saved, direct to stop point, otherwise direct to completed screen
    return (
        <>
            {!!currentLogin && !!surveyStatus ? (
                <>
                    {surveyStatus.surveyCompleted ? (
                        <>
                            {surveyStatus.topPriCompleted ?
                                <Redirect to={AuthRouteNames.Complete} />
                                :
                                <Redirect to={AuthRouteNames.Intermediate} />
                            }
                        </>
                    ) : (
                        <Redirect to={AuthRouteNames.Onboarding} />
                    )}
                </>
            ) : (
                        <LoadingSpinner />
            )}
        </>
    );
};
