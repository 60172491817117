import React from 'react';
import { RFC } from 'shared/types/sharedTypes';
import { ResetPasswordForm } from 'shared/components/ResetPasswordForm';
import { userApiManager } from '../../network/apiManager';
import { NonAuthRouteNames } from '../../navigation/routeNames';
import styled from 'styled-components';
import { BackToLink } from 'shared/components/Links';

export const ResetPassword: RFC = () => {
    return (
        <FormContainer>
            <BackToLink path={NonAuthRouteNames.Login} text={'Return to Login'} />
            <ResetPasswordForm apiManager={userApiManager} redirectPath={NonAuthRouteNames.Login} />
        </FormContainer>
    )
}

const FormContainer = styled.div`
  margin: 20px auto;
`;
