import React from 'react';
import styled from 'styled-components';
import { sharedTheming } from '../sharedTheming';

type ButtonToggleProps = {
    isOpen: boolean;
    onClick: () => void;
}

export const ButtonToggle = ({ isOpen, onClick }: ButtonToggleProps) => {

    return (
        <>
            {!isOpen ? (
                <ButtonToggleWrapper
                    onClick={onClick}
                >
                    <ButtonToggleContent>+</ButtonToggleContent>
                </ButtonToggleWrapper>
                ) : (
                <ButtonToggleWrapper
                    onClick={onClick}
                >
                    <ButtonToggleContent>-</ButtonToggleContent>
                </ButtonToggleWrapper>
            )}
        </>
    )
}

const ButtonToggleWrapper = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid ${sharedTheming.primaryButtonColor};
  border-radius: 50%;
  background-color: #FFF;
  width: 28px;
  height: 28px;
  box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.15);
  transition: all .3s ease-in-out;
    :hover& {
      cursor: pointer;
      transform: scale(1.25);
  }
`;

const ButtonToggleContent = styled.span`
  color: ${sharedTheming.primaryButtonColor};
  font-size: 20px;
  font-weight: bold;
  font-style: ${sharedTheming.regularFont};
  padding: 0;
  margin: 0;
`;