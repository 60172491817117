import React from 'react';
import { render } from 'react-dom';
import App from './App';
import SurveyContextProvider from './context/SurveyContext';
import UserContextProvider from './context/UserContext';

render(
    <UserContextProvider>
        <SurveyContextProvider>
            <App />
        </SurveyContextProvider>
    </UserContextProvider>,
    document.getElementById('app')
);
