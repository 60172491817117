import React, { ReactNode, useState } from 'react';
import { CategoryTitle } from './Text';
import styled from 'styled-components';
import { ButtonToggle } from './ButtonToggle';

type AccordionSectionProps = {
    title: string;
    children: ReactNode;
    showSection?: boolean;
};

export const AccordionSection = ({ title, children, showSection = false }: AccordionSectionProps) => {
    const [isOpen, setIsOpen] = useState<boolean>(showSection);

    return (
        <AccordionSectionContainer>
            <AccordionSectionHeader>
                <ButtonToggle isOpen={isOpen} onClick={() => setIsOpen(prevState => !prevState)} />
                <CategoryTitle
                    text={title}
                    onClick={() => {
                        setIsOpen(prevState => !prevState);
                    }}
                />
            </AccordionSectionHeader>
            <AccordionSectionContent show={isOpen}>{children}</AccordionSectionContent>
        </AccordionSectionContainer>
    );
};

const AccordionSectionHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 10px auto;
    :hover& {
      cursor: pointer;
    }
`;

const AccordionSectionContainer = styled.div`
    display: block;
    margin: 10px 0;
`;

const AccordionSectionContent = styled.div<{ show: boolean }>`
    display: ${({ show }) => (show ? 'block' : 'none')};
`;
