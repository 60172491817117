import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { SkipLink } from 'shared/components/Links';
import { AuthRouteNames } from '../../navigation/routeNames';
import { RFC } from 'shared/types/sharedTypes';
import { ErrorMessage, InfoText, PageTitle } from 'shared/components/Text';
import { UnlabeledTextInput } from 'shared/components/Input';
import { BaseButton } from 'shared/components/Buttons';
import { useAsync } from 'shared/hooks/useAsync';
import styled from 'styled-components';
import { Theming } from '../../theming';
import { userApiManager } from '../../network/apiManager';
import { UserContext } from '../../context/UserContext';

export const Onboarding: RFC = () => {
    const history = useHistory();
    const { setCurrentLogin } = useContext(UserContext);

    const [firstName, setFirstName] = useState<string>('');

    const onSubmit = async () => {
        try {
            await userApiManager.Users.updateCurrentUser({ firstName }, setCurrentLogin);
            history.push(`${AuthRouteNames.Tutorial}?page=1`);
        } catch(error) {
            console.log(error);
            throw error;
        }
    }

    const saveUserData = useAsync<void>(onSubmit, "Error saving your data. Please check your connection and try again.");

    return (
        <>
            <PageTitle text={'Enter First Name'} />
            <InfoText text={'You may skip this step if you do not wish to enter your name.'} />
            <FormWrapper>
                <UnlabeledTextInput
                    placeholder={''}
                    value={firstName}
                    onChange={e => {
                        //saving value to variable to prevent synthetic event error
                        const newValue = e.target.value;
                        setFirstName(newValue);
                    }}
                />
                <BaseButton
                    text={'Save and Continue'}
                    disabled={!firstName}
                    onClick={saveUserData.execute}
                />
                {saveUserData.error && <ErrorMessage text={saveUserData.error} />}
            </FormWrapper>
            <SkipLink path={`${AuthRouteNames.Tutorial}?page=1`} />
        </>
    )
}

const FormWrapper = styled.div`
  width: 60%;
  margin: 0 auto;
  max-width: 400px;
  @media (max-width: ${Theming.breakpoints.smallScreen}px) {
        width: 100%;
    }
`;