import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { RFC } from 'shared/types/sharedTypes';
import { ErrorMessage, InfoText, PageTitle } from 'shared/components/Text';
import { SurveyCategory } from './components/SurveyCategory';
import { BaseButton } from 'shared/components/Buttons';
import { AuthRouteNames } from '../../navigation/routeNames';
import { useAsync } from 'shared/hooks/useAsync';
import { SurveyContext } from '../../context/SurveyContext';
import { Importance } from 'shared/types/Survey';
import _ from 'lodash';
import { Modal } from 'shared/components/Modal';
import { modalContent } from '../../constants/modalContent';
import { adaptResponsesToApi, filterSurveyQuestions } from '../../adapters/surveyAdapters';
import { OrderedSectionSchema } from 'shared/types/Section';
import { UserContext } from '../../context/UserContext';
import LoadingSpinner from 'shared/components/Loader';
import { userApiManager } from '../../network/apiManager';
import { FormPrompt } from 'shared/components/FormPrompt';

type FormState = {
    showWarningModal: boolean;
    showErrorModal: boolean;
    isEditing: boolean;
};

export const Survey: RFC = () => {
    const { currentLogin, setSurveyStatus } = useContext(UserContext);
    const {
        surveyQuestions,
        setSurveyQuestions,
        surveyResponses,
        currentSection,
        setCurrentSection,
        isLastSection,
        clearSurvey,
    } = useContext(SurveyContext);

    const userRole = currentLogin?.userRole;
    const history = useHistory();

    //for form state
    const initialFormState = {
        showWarningModal: false, //display when no questions selected
        showErrorModal: false, //display when questions selected but not answered
        isEditing: true,
    };

    const [formState, setFormState] = useState<FormState>(initialFormState);
    const [surveySectionInfo, setSurveySectionInfo] = useState<OrderedSectionSchema | null>(null);

    useEffect(() => {
        //scroll window back to top when section changes
        window.scrollTo(0, 0)
    }, [currentSection])

    //fetch survey data on mount
    useEffect(() => {
        let isMounted = true;
        if (isMounted) {
            //on load, fetch survey data, set survey section to first page and create responses
            getSurvey.execute();
        }
        return () => {
            //on unmount, clear any errors and clear survey section so it is reset for next retake
            clearSurvey();
            getSurvey.clearError();
            isMounted = false;
        };
    }, []);

    useEffect(() => {
        //get survey data based on current section
        if (currentSection !== null && surveyQuestions) {
            setSurveySectionInfo(surveyQuestions.sections[currentSection]);
        }
    }, [currentSection, surveyQuestions]);

    const getSurveyAsync = async () => {
        try {
            if (currentLogin) {
                //TODO: may need to refactor in future if user is assigned multiple surveys
                const surveyId = currentLogin.surveyId;
                //fetch survey
                const surveyData = await userApiManager.Surveys.getSurvey({ surveyId });

                if (surveyData) {
                    //filter out empty sections of survey and save to context
                    setSurveyQuestions(filterSurveyQuestions(surveyData));
                }
            }
        } catch (error) {
            console.log(error);
            throw error;
        }
    };

    //TODO: figure out component unmount error
    const onSubmit = async () => {
        //clear errors if any
        saveSurveyResponses.clearError();
        try {
            if (surveyResponses) {
                const saveResponsesRequest = adaptResponsesToApi(surveyResponses);
                await userApiManager.Results.createResults(saveResponsesRequest, setSurveyStatus);
                if (!saveSurveyResponses.error) {
                    setFormState(prevState => {
                        return { ...prevState, isEditing: false };
                    });
                }
            }
        } catch (err) {
            console.log(err);
            throw err;
        }
        //navigate to next page
        goToNext();
    };

    //check that at least 1 question been selected
    const validateResponses = (): void => {
        let hasResponses = false;

        if (surveyResponses && currentSection !== null) {
            _.forIn(surveyResponses[currentSection], (response, key) => {
                if (!!response.answer && response.answer !== Importance.NOT_IMPORTANT) {
                    hasResponses = true;
                }
            });
        }

        //if no questions selected, show warning modal
        if (!hasResponses) {
            setFormState(prevState => {
                return { ...prevState, showWarningModal: true };
            });
        } else {
            onContinue();
        }
    };

    const onContinue = () => {
        if (isLastSection) {
            saveSurveyResponses.execute();
        } else {
            //navigate to next section
            goToNext();
        }
    };

    const goToNext = () => {
        if (!isLastSection) {
            //navigate to next section
            setCurrentSection(prevState => (prevState !== null ? prevState + 1 : prevState));
        } else {
            //navigate to next page
            history.push(AuthRouteNames.Intermediate);
        }
    };

    const getSurvey = useAsync<void>(getSurveyAsync, 'Survey not found');
    const saveSurveyResponses = useAsync<void>(
        onSubmit,
        'Error saving responses. Please check your connection and try again.'
    );


    return (
        <>
            {!!userRole && !!surveySectionInfo && surveySectionInfo.categories.length ? (
                <>
                    <PageTitle text={surveySectionInfo.title} />
                    <InfoText text={surveySectionInfo.description[userRole] ?? ''} />
                    {surveySectionInfo.categories.map((category, index, array) => {
                        return (
                            <SurveyCategory
                                key={index}
                                title={category.description[userRole] ?? ''}
                                questions={category.questions}
                                userRole={userRole}
                                useHeader={array.length > 1}
                            />
                        );
                    })}
                    <BaseButton text={!isLastSection ? 'Continue' : 'Complete'} onClick={validateResponses} />

                    <Modal
                        isOpen={formState.showWarningModal}
                        hide={() =>
                            setFormState(prevState => {
                                return { ...prevState, showWarningModal: false };
                            })
                        }
                        text={modalContent.warnings.survey.text}
                        title={modalContent.warnings.survey.title}
                        onConfirm={() => {
                            setFormState(prevState => {
                                return { ...prevState, showWarningModal: false };
                            });
                            onContinue();
                        }}
                        reverseButtons
                    />
                    <Modal
                        isOpen={formState.showErrorModal}
                        hide={() =>
                            setFormState(prevState => {
                                return { ...prevState, showErrorModal: false };
                            })
                        }
                        title={modalContent.errors.title}
                        confirmButtonText={'Okay'}
                        onConfirm={() => {
                            setFormState(prevState => {
                                return { ...prevState, showErrorModal: false };
                            });
                        }}
                        hideCancel
                    />
                    {/*warns before browser refresh/back button*/}
                    <FormPrompt when={formState.isEditing} />
                </>
            ) : (
                <>
                    {!!getSurvey.error ? (
                        <>
                            <ErrorMessage text={getSurvey.error} />
                        </>
                    ) : (
                        <LoadingSpinner />
                    )}
                </>
            )}
        </>
    );
};
