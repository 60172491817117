import { UserRole } from 'shared/types/User';

export const contentStrings = {
    login: {
        title: 'Welcome to PACES!',
        info:
            'Please enter your User ID and Password below. ' +
            '\n\nFor help, please contact the research staff at ',
    },
    tutorial: [
        {
            title: 'Welcome to PACES!',
            info: {
                [UserRole.PATIENT]:
                    'PACES is designed to help patients and caregivers think about their priorities and get on the same page to make the most of their time with the cancer care provider. ' +
                    '\n\nIf you have someone who provides a majority of support to you, like a family member, friend, or other unpaid person, please think about this person when responding. ' +
                    'If you have someone who regularly attends appointments with you, they are most likely the “caregiver” we are asking about.',
                [UserRole.CARE_PARTNER]:
                    'PACES is designed to help patients and caregivers think about their priorities and get on the same page to make the most of their time with the cancer care provider. ' +
                    '\n\nA caregiver is someone who provides a majority of support to a patient, like a family member, friend, or other unpaid person. ' +
                    'A caregiver may also regularly attend appointments with a patient.',
            },
        },
        {
            title: 'Provider Questions',
            info: {
                [UserRole.PATIENT]:
                    "To help you think about what's most important, <b>we'll first present you with a list of questions you may want to ask your cancer care provider.</b> " +
                    "\n\nPlease select an importance level for each question. " +
                    "\n\nLater we'll help you and your caregiver compare how you answered these questions.",
                [UserRole.CARE_PARTNER]:
                    "To help you think about what's most important, <b>we'll first present you with a list of questions you may want to ask the cancer care provider.</b> " +
                    "\n\nPlease select an importance level for each question. " +
                    "\n\nLater we'll help you and your patient compare how you answered these questions.",
            },
        },
        {
            title: 'Caregiver Role',
            info: {
                [UserRole.PATIENT]:
                    "Once you have chosen the questions that are important to you, <b>we'll present you with a list of things that caregivers might do to be helpful during the cancer care appointment.</b> " +
                    "\n\nPlease select an importance level for each question. " +
                    "\n\nLater, we'll help you and your caregiver compare how you prioritized the caregiver activities.",
                [UserRole.CARE_PARTNER]:
                    "Once you have chosen the questions that are important to you, <b>we'll present you with a list of things that caregivers might do to be helpful during the cancer care appointment.</b> " +
                    "\n\nPlease select an importance level for each question. " +
                    "\n\nLater, we'll help you and your patient compare how you prioritized the caregiver activities.",
            },
        },
        {
            title: 'Summary',
            info: {
                [UserRole.PATIENT]:
                    'After you complete your responses, we will present you with a summary of your answers and the answers provided by your caregiver. ' +
                    '\n\n<b>We encourage you to discuss your answers together and decide on 3-5 top questions to ask during your next cancer care appointment and 1-2 things that are most important for the caregiver to do.</b>',
                [UserRole.CARE_PARTNER]:
                    'After you complete your responses, we will present you with a summary of your answers and the answers provided by your patient. ' +
                    '\n\n<b>We encourage you to discuss your answers together and decide on 3-5 top questions to ask during the next cancer care appointment and 1-2 things that are most important for the caregiver to do.</b>',
            },
        },
    ],
    summary: {
        title: 'Summary',
        info: {
            [UserRole.PATIENT]:
                'Below are the responses from you and your caregiver. \n\n<b>Please review your responses and together decide what your priorities are. ' +
                " \n\nClick the stars</b> to identify 3-5 top questions to ask your cancer provider. \n\nWhen you're finished, click SAVE at the bottom.",
            [UserRole.CARE_PARTNER]:
                'Below are the responses from you and your patient. \n\n<b>Please review your responses and together decide what your priorities are. ' +
                " \n\nClick the stars</b> to identify 3-5 top questions to ask your cancer provider. \n\nWhen you're finished, click SAVE at the bottom.",
        },
    },
    complete: {
        title: 'Complete',
        info:
            'Your survey is complete and your responses and top priorities have been saved. ' +
            '\n\nNow that you have chosen your priorities, you can use the buttons below to download your summary or have it sent to you in an email. ' +
            '\n\n<b>You may find that it is helpful to bring a copy of the summary with you to your next cancer care appointment.</b> ' +
            '\n\nYou can always return to this site to see your summary and edit your responses.',
    }
};
