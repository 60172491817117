import React, { useContext, useEffect, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { UserContext } from './context/UserContext';
import { userApiManager } from './network/apiManager';
import { useAsync } from 'shared/hooks/useAsync';
import { NonAuthRouteNames } from './navigation/routeNames';
import PacesLogo from '../../shared/assets/logos/pacesLogoTransparent.png';
import IconManager, { IconType } from 'shared/components/IconManager';
import { Theming } from './theming';
import { NavMenu } from './components/NavMenu';
import { useOutsideAlerter } from 'shared/hooks/useOutsideAlerter';
import { Logo, LogoSpacer, MenuToggle, MenuWrapper } from 'shared/components/Nav';
import styled from 'styled-components';
import LoadingSpinner from 'shared/components/Loader';
import { LoadingModal } from 'shared/components/LoadingModal';
import { isProd } from '../environmentConfig';

export const NavHeader = () => {
    const history = useHistory();
    const { currentLogin, setCurrentLogin } = useContext(UserContext);

    const [showHiddenLinks, setShowHiddenLinks] = useState<boolean>(!!currentLogin);
    const [showMenu, setShowMenu] = useState<boolean>(false);

    //to close menu when clicking anywhere else in window
    const wrapperRef = useRef(null);
    useOutsideAlerter({ ref: wrapperRef, callback: () => setShowMenu(false) });

    useEffect(() => {
        // toggle button visibility when user signs in/out
        if (currentLogin && !showHiddenLinks) {
            setShowHiddenLinks(true);
        } else if (!currentLogin && showHiddenLinks) {
            setShowHiddenLinks(false);
        }
    }, [currentLogin]);

    const logoutAsync = async () => {
        try {
            await userApiManager.Auth.logout(setCurrentLogin);
        } catch (error) {
            console.log(error);
            throw error;
        }
        history.push(NonAuthRouteNames.Login);
    };

    const logout = useAsync<void>(logoutAsync);

    return (
        <HeaderWrapper>
            <LogoSpacer />
            <LogoWrapper>
                <Link to={'/'}>
                    <Logo src={PacesLogo} />
                </Link>
            </LogoWrapper>
            {/*hide empty menu on prod logged out screen*/}
            {!isProd || !!currentLogin ? (
                <MenuWrapper ref={wrapperRef}>
                    <MenuToggle>
                        <IconManager
                            type={IconType.HAMBURGER}
                            stroke={Theming.primaryIconColor}
                            size={28}
                            onClick={() => {
                                setShowMenu(prevState => !prevState);
                            }}
                        />
                    </MenuToggle>
                    {showMenu && (
                        <NavMenu
                            showHiddenLinks={showHiddenLinks}
                            onLogout={logout.execute}
                            closeMenu={() => setShowMenu(false)}
                        />
                    )}
                </MenuWrapper>
            ) :
                <LogoSpacer />
            }
            {/*show loading spinner on logout*/}
            <LoadingModal isOpen={logout.pending} spinner={<LoadingSpinner />} />
        </HeaderWrapper>
    );
};

const HeaderWrapper = styled.header`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 5px 0;
    margin: 0;
    text-align: center;
    background-color: transparent;
`;

const LogoWrapper = styled.div`
    display: flex;
    width: 20%;
    min-width: 300px;
    @media (max-width: ${Theming.breakpoints.smallScreen}px) {
        width: 50%;
        max-width: 300px;
        min-width: 0;
    }
    justify-content: center;
    align-items: center;
`;

