import React from 'react';
import styled from 'styled-components';
import { sharedTheming } from '../sharedTheming';
import ReactHtmlParser from 'react-html-parser';

type TextProps = {
    text: string;
    onClick?: () => void;
    textAlign?: 'left' | 'right' | 'center';
    margin?: string
    inline?: boolean
    italic?: boolean
    color?: string
    bold?: boolean
    width?: string
    fontSize?: number
};

export const PageTitle = ({ text, textAlign = 'center', margin }: TextProps) => {
    return <StyledPageTitle textAlign={textAlign} margin={margin}>{text}</StyledPageTitle>;
};

export const BodyText = ({ text }: TextProps) => {
    return <StyledBodyText>{text}</StyledBodyText>;
};

export const InfoText = ({ text, inline, italic, color, bold, margin, width, textAlign, fontSize }: TextProps) => {
    return (
        <StyledInfoText
        inline={inline}
        italic={italic}
        bold={bold}
        color={color}
        margin={margin}
        width={width}
        textAlign={textAlign}
        fontSize={fontSize}
        >
            {ReactHtmlParser(text)}
        </StyledInfoText>
    )
};

export const CategoryTitle = ({ text, onClick }: TextProps) => {
    return <StyledCategoryTitle onClick={onClick}>{text}</StyledCategoryTitle>;
};

export const ErrorMessage = ({ text, margin }: TextProps) => {
    return <StyledErrorMessage margin={margin}>{text}</StyledErrorMessage>;
};

const StyledPageTitle = styled.h1<{ textAlign: string, margin?: string}>`
    font-size: 22px;
    font-weight: bold;
    text-align: ${({ textAlign }) => (textAlign)};
    font-family: ${sharedTheming.regularFont};
    color: ${sharedTheming.titleTextColor};
    margin: ${({ margin }) => (margin ?? 'auto')};
`;

const StyledBodyText = styled.p`
    display: inline-block;
    font-size: 16px;
    text-align: left;
    font-family: ${sharedTheming.regularFont};
    color: ${sharedTheming.primaryTextColor};
    margin: 0 auto;
`;

export const StyledInfoText = styled.p<{ inline?: boolean; italic?: boolean; color?: string; bold?: boolean; margin?: string; width?: string; textAlign?: string; fontSize?: number}>`
    display: ${({ inline }) => (inline ? 'inline-block' : 'block')};
    font-size: ${({ fontSize }) => (fontSize ?? 18)}px;
    font-weight: lighter;
    text-align: ${({ textAlign }) => (textAlign ?? 'center')};
    font-family: ${sharedTheming.regularFont};
    color: ${({ color }) => (color ?? sharedTheming.secondaryTextColor)};
    white-space: pre-wrap;
    font-style: ${({ italic }) => (italic ? 'italic' : 'normal')};
    font-weight: ${({ bold }) => (bold ? 'bold' : 'normal')};
    margin: ${({ margin }) => (margin ?? '16px 0px')};
    width: ${({ width }) => (width ?? 'auto')};
`;

const StyledCategoryTitle = styled.h2`
    display: inline-block;
    padding: 0 10px;
    font-size: 20px;
    font-weight: bold;
    text-align: left;
    font-family: ${sharedTheming.regularFont};
    color: ${sharedTheming.subTitleTextColor};
`;

const StyledErrorMessage = styled.p<{ margin?: string}>`
    color: ${sharedTheming.errorColor};
    text-align: center;
    min-height: 1em;
    margin: ${({ margin }) => (margin ?? '0.5rem auto')};
    font-size: 1rem;
    font-family: ${sharedTheming.regularFont};
    white-space: pre-wrap;
`;
