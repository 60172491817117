import React, { useContext, useEffect, useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { RFC } from 'shared/types/sharedTypes';
import { InfoText, PageTitle } from 'shared/components/Text';
import { contentStrings } from '../../constants/contentStrings';
import { BaseButton } from 'shared/components/Buttons';
import { AuthRouteNames } from '../../navigation/routeNames';
import { ImportanceOptions } from '../../components/ImportanceOptions';
import { SectionIndicator, SectionIndicatorWrapper } from './components/SectionIndicator';
import { SkipLink } from 'shared/components/Links';
import styled from 'styled-components';
import { Theming } from '../../theming';
import { AvatarPair } from 'shared/components/AvatarPair';
import { Importance } from 'shared/types/Survey';
import { UserContext } from '../../context/UserContext';
import LoadingSpinner from 'shared/components/Loader';
import { useQuery } from 'shared/hooks/useQuery';

export const Tutorial: RFC = () => {
    const pageNumber = Number(useQuery().get('page'));
    const history = useHistory();
    const { currentLogin } = useContext(UserContext);
    const pageInfo = contentStrings.tutorial;

    const [currentSection, setCurrentSection] = useState<number>(pageNumber - 1);
    const [isLastSection, setIsLastSection] = useState<boolean>(currentSection === pageInfo.length - 1);
    const [isSurveySection, setIsSurveySection] = useState<boolean>(currentSection === 1 || currentSection === 2);

    useEffect(() => {
        setCurrentSection(pageNumber - 1);
    }, [pageNumber]);

    useEffect(() => {
        const shouldSetLastSection = currentSection === pageInfo.length - 1;
        const shouldSetSurveySection = currentSection === (1 || 2);

        //to change button text on final section
        if (shouldSetLastSection && !isLastSection) {
            setIsLastSection(true);
        } else if (!shouldSetLastSection && isLastSection) {
            setIsLastSection(false);
        }
        //to toggle display of icons on survey onboarding pages
        if (shouldSetSurveySection && !isSurveySection) {
            setIsSurveySection(true);
        } else if (!shouldSetSurveySection && isSurveySection) {
            setIsSurveySection(false);
        }
    }, [currentSection]);

    return (
        <>
            {!!currentLogin ? (
                <>
                    {!!pageInfo[currentSection] ? (
                        <>
                            <PageTitle text={pageInfo[currentSection].title} />
                            <TutorialInfoWrapper>
                                <InfoText text={pageInfo[currentSection].info[currentLogin.userRole]} />
                            </TutorialInfoWrapper>

                            {!!isSurveySection ? (
                                <ImportanceOptions
                                    initialValue={Importance.NOT_IMPORTANT}
                                    onChange={newValue => console.log(newValue)}
                                />
                            ) : (
                                <AvatarPair userRole={currentLogin.userRole} />
                            )}

                            <BaseButton
                                isPrimary={false}
                                text={!isLastSection ? 'Continue' : 'Start'}
                                onClick={() => history.push(`${AuthRouteNames.Tutorial}?page=${pageNumber + 1}`)}
                            />

                            <SectionIndicatorWrapper>
                                {pageInfo.map((section, index) => {
                                    return (
                                        <SectionIndicator
                                            key={index}
                                            active={index === currentSection}
                                            onClick={() => history.push(`${AuthRouteNames.Tutorial}?page=${index + 1}`)}
                                        />
                                    );
                                })}
                            </SectionIndicatorWrapper>

                            <SkipLink path={AuthRouteNames.Survey} />
                        </>
                    ) : (
                        <Redirect to={AuthRouteNames.Survey} />
                    )}
                </>
            ) : (
                <LoadingSpinner />
            )}
        </>
    );
};

const TutorialInfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 30vh;
    @media (max-width: ${Theming.breakpoints.smallScreen}px) {
        min-height: 40vh;
    }
`;

