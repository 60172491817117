import { SurveyQuestion } from '../../survey/components/SurveyOption';
import { AvatarPair } from 'shared/components/AvatarPair';
import React from 'react';
import { UserRole } from 'shared/types/User';
import { QuestionType } from 'shared/types/Question';
import { Importance, PairResult } from 'shared/types/Result';
import { getImportanceString } from 'shared/constants/importanceStrings';
import IconManager, { IconType } from 'shared/components/IconManager';
import styled from 'styled-components';
import { Theming } from '../../../theming';
import { Separator } from 'shared/components/Separator';
import { ResultType } from '../../../adapters/summaryAdapters';

type SummaryResultProps = {
    question: string;
    response: PairResult['answer'] | string;
    userRole: UserRole;
    questionType?: QuestionType;
    iconType?: IconType;
    onClick: () => void;
    priorityType: ResultType;
    disableRanking?: boolean;
};

export const SummaryResult = ({
    question,
    response,
    userRole,
    questionType = QuestionType.IMPORTANCE,
    iconType,
    onClick,
    priorityType,
    disableRanking = false,
}: SummaryResultProps) => {
    const primaryAnswer = response[UserRole.PATIENT] as Importance;
    const secondaryAnswer = response[UserRole.CARE_PARTNER] as Importance;

    return (
        <>
            {questionType === QuestionType.IMPORTANCE ? (
                <>
                    <SummaryResultContainer>
                        {!disableRanking && (
                            <IconManager
                                type={iconType ?? IconType.STAR_NO_FILL}
                                stroke={Theming.tertiaryIconColor}
                                size={24}
                                onClick={onClick}
                            />
                        )}
                        <ResultContentWrapper>
                            <SurveyQuestion>{question}</SurveyQuestion>
                        </ResultContentWrapper>
                    </SummaryResultContainer>
                    <AvatarPair
                        userRole={userRole}
                        primaryLabel={!!primaryAnswer ? getImportanceString(primaryAnswer) : "N/A"}
                        secondaryLabel={!!secondaryAnswer ? getImportanceString(secondaryAnswer) : "N/A"}
                        primaryActive={!!primaryAnswer && primaryAnswer !== Importance.NOT_IMPORTANT}
                        secondaryActive={!!secondaryAnswer && secondaryAnswer !== Importance.NOT_IMPORTANT}
                    />
                    <Separator />
                </>
            ) : (
                <>
                    <SummaryResultContainer>
                        {!disableRanking && (
                            <IconManager
                                type={iconType ?? IconType.STAR_NO_FILL}
                                stroke={Theming.tertiaryIconColor}
                                size={24}
                                onClick={onClick}
                            />
                        )}
                        <ResultContentWrapper>
                            <SurveyQuestion>
                                {priorityType === ResultType.NON_RANKED_PATIENT
                                    ? response[UserRole.PATIENT]
                                    : response[UserRole.CARE_PARTNER]}
                            </SurveyQuestion>
                        </ResultContentWrapper>
                    </SummaryResultContainer>
                    <Separator />
                </>
            )}
        </>
    );
};

const SummaryResultContainer = styled.div`
    display: flex;
    margin: 0 0 0 20px;
`;

const ResultContentWrapper = styled.div`
    flex: 1;
    padding: 2px 10px;
    text-align: left;
`;
