import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { RFC } from 'shared/types/sharedTypes';
import { ErrorMessage, InfoText, PageTitle } from 'shared/components/Text';
import { contentStrings } from '../../constants/contentStrings';
import { ButtonWrapper } from 'shared/components/Wrappers';
import { BaseButton, LogoutButton } from 'shared/components/Buttons';
import { AuthRouteNames, NonAuthRouteNames } from '../../navigation/routeNames';
import { EmailModal } from 'shared/components/EmailModal';
import { useAsync } from 'shared/hooks/useAsync';
import { userApiManager } from '../../network/apiManager';
import LoadingSpinner, { MiniLoadingSpinner } from 'shared/components/Loader';
import { handleDownloadFile } from 'shared/utils/handleDownloadFile';
import { UserContext } from '../../context/UserContext';
import { LoadingModal } from 'shared/components/LoadingModal';

export const Complete: RFC = () => {
    const history = useHistory();
    const { setCurrentLogin } = useContext(UserContext);


    const [showEmailModal, setShowEmailModal] = useState<boolean>(false);
    const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false);

    const onSubmitEmail = async (email) => {
        try {
            //submit request
            await userApiManager.Results.emailPdfResults({ email })
            //show success message
            setShowSuccessMessage(true);
        } catch(error) {
            console.log(error);
            throw error;
        }
    }

    const onSubmitDownload = async () => {
        try {
            //submit request
            const getPdfResponse = await userApiManager.Results.getPdfResults({});
            //create url from response blob, generate link, and auto-click to download pdf
            await handleDownloadFile(getPdfResponse, 'pdf');
        } catch(error) {
            console.log(error);
            throw error;
        }
    }

    const onLogout = async () => {
        try {
            await userApiManager.Auth.logout(setCurrentLogin);
            history.push(NonAuthRouteNames.Login);
        } catch (error) {
            console.log(error);
            throw error;
        }
    };

    const logout = useAsync<void>(onLogout);

    const emailPDF = useAsync<void>(async (email) => onSubmitEmail(email), "Error sending your request. \nPlease check your connection and try again.");
    const downloadPDF = useAsync<any>(onSubmitDownload, "Error sending your request. \nPlease check your connection and try again.");

    return (
        <>
            <PageTitle text={contentStrings.complete.title} />
            <InfoText text={contentStrings.complete.info} />

            {!!downloadPDF.error && <ErrorMessage text={downloadPDF.error} />}
            <ButtonWrapper>
                <BaseButton
                    text={!downloadPDF.pending ? 'Download Summary PDF' : <MiniLoadingSpinner />}
                    disabled={!!downloadPDF.pending}
                    onClick={downloadPDF.execute}
                />
                <BaseButton text={'Email Summary PDF'} onClick={() => setShowEmailModal(true)} />
                <BaseButton
                    text={'View Summary'}
                    isPrimary={false}
                    onClick={() => history.push(AuthRouteNames.Summary)}
                />
                <BaseButton
                    text={'Retake Full Survey'}
                    isPrimary={false}
                    onClick={() => history.push(AuthRouteNames.Survey)}
                />
                <LogoutButton
                    text={'Sign Out'}
                    onClick={logout.execute}
                />
            </ButtonWrapper>
            <EmailModal
                isOpen={showEmailModal}
                hide={() => {
                    //clear any errors
                    if (emailPDF.error) {
                        emailPDF.clearError();
                    }
                    //close modal
                    setShowEmailModal(false);
                }}
                onConfirm={emailPDF.execute}
                submitError={emailPDF.error ?? ''}
                showSuccessMessage={showSuccessMessage}
            />
            {/*show loading spinner on logout*/}
            <LoadingModal isOpen={logout.pending} spinner={<LoadingSpinner />} />
        </>
    );
};
