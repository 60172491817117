import React, { useContext } from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { AuthRoute, NonAuthRoute } from './navigation/RouteComponents';
import { AuthRouteNames, NonAuthRouteNames } from './navigation/routeNames';
import { Login } from './pages/auth/Login';
import { Tutorial } from './pages/onboarding/Tutorial';
import { Summary } from './pages/summary/Summary';
import { Background } from 'shared/components/Wrappers';
import { Survey } from './pages/survey/Survey';
import { Complete } from './pages/complete/Complete';
import { UserContext } from './context/UserContext';
import { RedirectToStart } from './pages/RedirectToStart';
import LoadingSpinner from 'shared/components/Loader';
import { NavHeader } from './NavHeader';
import { MainContainer } from './components/MainContainer';
import { Unauthorized } from 'shared/components/Unauthorized';
import './App.css';
import { Onboarding } from './pages/onboarding/Onboarding';
import { Profile } from './pages/profile/Profile';
import { NotFound } from 'shared/components/NotFound';
import { CreateAccount } from './pages/auth/CreateAccount';
import { Theming } from './theming';
import { ResetPassword } from './pages/auth/ResetPassword';
import { IdleTimeoutWrapper } from 'shared/components/IdleTimeoutWrapper';
import { userApiManager } from './network/apiManager';
import { minutesToMS } from 'shared/utils/minutesToMS';
import { Intermediate } from './pages/Intermediate';


const App = () => {
    const { currentLogin, userLoading, setCurrentLogin } = useContext(UserContext);

    return (
        <>
            <Background backgroundColor={Theming.primaryBackgroundColor} />
            <Router>
                <NavHeader />
                <MainContainer>
                    {!currentLogin ? (
                        <>
                            {!userLoading ? (
                                <Switch>
                                    <NonAuthRoute component={<Login />} path={NonAuthRouteNames.Login} exact />
                                    <NonAuthRoute component={<CreateAccount />} path={NonAuthRouteNames.CreateAccount} />
                                    <NonAuthRoute component={<ResetPassword />} path={NonAuthRouteNames.ResetPassword} />
                                    <NonAuthRoute component={<Unauthorized />} />
                                </Switch>
                            ) : (
                                <NonAuthRoute component={<LoadingSpinner />} />
                            )}
                        </>
                    ) : (
                        // auth switch router wrapped in timeout functionality to logout user after period of inactivity
                        <IdleTimeoutWrapper
                            onLogout={async () => await userApiManager.Auth.logout(setCurrentLogin)}
                            logoutPath={NonAuthRouteNames.Login}
                            onReset={async () => await userApiManager.resetTokenExpiration()}
                            countdownTime={minutesToMS(15)}
                        >
                            <Switch>
                                <AuthRoute component={<RedirectToStart />} exact path={AuthRouteNames.RedirectToStart} />
                                <AuthRoute component={<Onboarding />} exact path={AuthRouteNames.Onboarding} />
                                <AuthRoute component={<Tutorial />} path={AuthRouteNames.Tutorial} />
                                <AuthRoute component={<Survey />} path={AuthRouteNames.Survey} />
                                <AuthRoute component={<Summary />} path={AuthRouteNames.Summary} />
                                <AuthRoute component={<Complete />} path={AuthRouteNames.Complete} />
                                <AuthRoute component={<Profile />} path={AuthRouteNames.Profile} />
                                <AuthRoute component={<Intermediate />} path={AuthRouteNames.Intermediate} />
                                <AuthRoute component={<NotFound />} />
                            </Switch>
                        </IdleTimeoutWrapper>
                    )}
                </MainContainer>
            </Router>
        </>
    );
};

export default App;
