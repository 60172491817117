import React, { createContext, Dispatch, ReactNode, SetStateAction, useEffect, useState, useRef } from 'react';
import { userApiManager } from '../network/apiManager';
import { NonAdmin, UserRole } from 'shared/types/User';
import { useAsync } from 'shared/hooks/useAsync';

type UserContextType = {
    currentLogin: NonAdmin | null | undefined;
    userLoading: boolean;
    setUserLoading: Dispatch<SetStateAction<boolean>>;
    setCurrentLogin: Dispatch<SetStateAction<NonAdmin | null | undefined>>;
    surveyStatus: SurveyStatus | undefined;
    setSurveyStatus: Dispatch<SetStateAction<SurveyStatus | undefined>>;
};

export type SurveyStatus = {
    surveyCompleted: boolean;
    topPriCompleted: boolean;
}

export const UserContext = createContext<UserContextType>({
    currentLogin: undefined,
    userLoading: false,
    setUserLoading: () => {},
    setCurrentLogin: () => {},
    surveyStatus: undefined,
    setSurveyStatus: () => {}
});

export default function UserContextProvider({ children }: { children: ReactNode }) {
    const [userLoading, setUserLoading] = useState<boolean>(true);
    const [currentLogin, setCurrentLogin] = useState<NonAdmin | null | undefined>(undefined);
    const [surveyStatus, setSurveyStatus] = useState<SurveyStatus | undefined>(undefined)

    useEffect(() => {
        console.log('current user update', currentLogin);
        //login/refresh logic
        (async () => {
            try {
                if (currentLogin) {
                    //refetch and set survey status when user updates
                    await getSurveyStatus.execute();
                    setUserLoading(false);
                } else if (currentLogin === undefined) {
                    //clear survey status on logout
                    setSurveyStatus(undefined);
                    //refresh
                    await userApiManager.Auth.refresh({ setCurrentLogin });
                    setUserLoading(false);
                } else {
                    setUserLoading(false);
                }
            } catch (error) {
                console.log(error);
                setCurrentLogin(null);
                setUserLoading(false);
            }
        })();
    }, [currentLogin]);

    //when user is updated, refetch survey status and update
    const getSurveyStatusAsync = async () => {
        try {
            if (currentLogin) {
                //fetch survey completion dates for all users
                const resultDates = await userApiManager.Results.getDates({});

                //find date for current user
                const userCompletedDate = resultDates[currentLogin.username]?.completionDates[0];

                //get top priority completion status for patient regardless of user role (only patients can set top priorities)
                const patientTopPriCompleted = currentLogin.userRole === UserRole.PATIENT ? resultDates[currentLogin.username]?.topPriCompleted : resultDates[currentLogin.pairUsername]?.topPriCompleted;

                setSurveyStatus({
                    surveyCompleted: !!userCompletedDate,
                    topPriCompleted: !!patientTopPriCompleted
                });
            }
        } catch (error) {
            console.log(error);
            throw error;
        }
    }

    const getSurveyStatus = useAsync<void>(getSurveyStatusAsync, "Could not find survey status")

    return (
        <UserContext.Provider
            value={{
                currentLogin,
                userLoading,
                setUserLoading,
                setCurrentLogin,
                surveyStatus,
                setSurveyStatus
            }}
        >
            {children}
        </UserContext.Provider>
    );
}
