import React, { useEffect, useState } from 'react';
import { CircleIcon } from 'shared/components/CircleIcon';
import { IconGrouping, IconGroupingColumn } from 'shared/components/Wrappers';
import { iconResponsiveSizing } from 'shared/utils/iconResponsiveSizing';
import { Importance } from 'shared/types/Survey';
import { getImportanceString } from 'shared/constants/importanceStrings';

type ImportanceOptionsProps = {
    color?: string;
    initialValue: Importance;
    textAlign?: 'left' | 'center' | 'right';
    onChange?: (importanceValue: Importance) => void;
};

export const ImportanceOptions = ({ color, initialValue, textAlign, onChange }: ImportanceOptionsProps) => {
    const size = iconResponsiveSizing();

    const [importance, setImportance] = useState<Importance>(initialValue);

    useEffect(() => {
        //when selection changes, invoke onChange function passed from parent component with importance value
        if (onChange) {
            onChange(importance);
        }
    }, [importance]);

    return (
        <IconGrouping>
            <IconGroupingColumn textAlign={textAlign}>
                <CircleIcon
                    symbol={'1'}
                    label={getImportanceString(Importance.NOT_IMPORTANT)}
                    size={size}
                    color={color}
                    active={importance === Importance.NOT_IMPORTANT}
                    onClick={() => {
                        if (importance !== Importance.NOT_IMPORTANT) {
                            setImportance(Importance.NOT_IMPORTANT);
                        }
                    }}
                />
            </IconGroupingColumn>
            <IconGroupingColumn textAlign={textAlign}>
                <CircleIcon
                    symbol={'2'}
                    label={'Important'}
                    size={size}
                    color={color}
                    active={importance === Importance.IMPORTANT}
                    onClick={() => {
                        if (importance !== Importance.IMPORTANT) {
                            setImportance(Importance.IMPORTANT);
                        }
                    }}
                />
            </IconGroupingColumn>
            <IconGroupingColumn textAlign={textAlign}>
                <CircleIcon
                    symbol={'3'}
                    label={'Very Important'}
                    size={size}
                    color={color}
                    active={importance === Importance.VERY_IMPORTANT}
                    onClick={() => {
                        if (importance !== Importance.VERY_IMPORTANT) {
                            setImportance(Importance.VERY_IMPORTANT);
                        }
                    }}
                />
            </IconGroupingColumn>
        </IconGrouping>
    );
};
