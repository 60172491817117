import { Importance, PairResult, UpdateResultRequest, ViewResultResponse } from 'shared/types/Result';
import { UserRole } from 'shared/types/User';
import { QuestionType } from 'shared/types/Question';
import _ from 'lodash';
import { adaptResultsToSections, ResultSections } from 'shared/adapters/surveyAdapters';

export enum ResultType {
    NOT_IMPORTANT = 1,
    IMPORTANT_TO_CAREGIVER,
    IMPORTANT_TO_PATIENT,
    IMPORTANT_TO_BOTH ,
    TOP_PRIORITY,
    NON_RANKED_PATIENT,
    NON_RANKED_PARTNER,
}

export type RankedPairResults = {
    [key in ResultType]: RankedResult[];
}

export type RankedResult = PairResult & {
    priorityType: ResultType;
};

export const adaptResultsToForm = (results: ViewResultResponse): RankedPairResults => {
    //sort results into new sections
    const sortedResults = adaptResultsToSections(results);

    //assign types for not important
    const notImportantResults = sortedResults[ResultSections.NOT_IMPORTANT].map(result => {
        return { ...result, priorityType: ResultType.NOT_IMPORTANT };
    });

    //assign types for important to caregiver
    const importantToCaregiver = sortedResults[ResultSections.IMPORTANT_TO_CAREGIVER].map(result => {
        return { ...result, priorityType: ResultType.IMPORTANT_TO_CAREGIVER };
    });

    //assign types for important to patient
    const importantToPatient = sortedResults[ResultSections.IMPORTANT_TO_PATIENT].map(result => {
        return { ...result, priorityType: ResultType.IMPORTANT_TO_PATIENT };
    });

    //assign types for important to both
    const importantToBoth = sortedResults[ResultSections.IMPORTANT_TO_BOTH].map(result => {
        return { ...result, priorityType: ResultType.IMPORTANT_TO_BOTH}
    })

    //assign types for top priority
    const topResults = results.topPriority.map(result => {
        const patientAnswer = result.answer[UserRole.PATIENT] as Importance;
        const partnerAnswer = result.answer[UserRole.CARE_PARTNER] as Importance;

        //set which collection to put the item if it's deselected as "top priority"
        if (result.questionType === QuestionType.IMPORTANCE) {
            //use the importance level of combined results
            const priorityType = patientAnswer !== Importance.NOT_IMPORTANT && partnerAnswer !== Importance.NOT_IMPORTANT
                    ? ResultType.IMPORTANT_TO_BOTH
                    : patientAnswer !== Importance.NOT_IMPORTANT && partnerAnswer === Importance.NOT_IMPORTANT
                        ? ResultType.IMPORTANT_TO_PATIENT
                        : patientAnswer === Importance.NOT_IMPORTANT && partnerAnswer !== Importance.NOT_IMPORTANT
                            ? ResultType.IMPORTANT_TO_CAREGIVER
                            : ResultType.NOT_IMPORTANT;
            return { ...result, priorityType };
        } else {
            //if free text, set as patient or partner's response
            const priorityType = !!result.answer[UserRole.PATIENT]
                ? ResultType.NON_RANKED_PATIENT
                : ResultType.NON_RANKED_PARTNER;
            return { ...result, priorityType };
        }
    });

    //split nonRanked results by user role and assign types
    const nonRankedPatientResults = results.nonRankedQuestions.reduce((patientResults, result) => {
        //filter out care partner answers and blank answers
        if (!!result.answer[UserRole.PATIENT]) {
            const patientResult = {
                ...result,
                priorityType: ResultType.NON_RANKED_PATIENT,
            };
            patientResults.push(patientResult);
        }
        return patientResults;
    }, [] as RankedResult[]);

    const nonRankedPartnerResults = results.nonRankedQuestions.reduce((partnerResults, result) => {
        //filter out patient answers and blank answers
        if (!!result.answer[UserRole.CARE_PARTNER]) {
            const patientResult = {
                ...result,
                priorityType: ResultType.NON_RANKED_PARTNER,
            };
            partnerResults.push(patientResult);
        }
        return partnerResults;
    }, [] as RankedResult[]);

    return {
        [ResultType.NOT_IMPORTANT]: notImportantResults,
        [ResultType.IMPORTANT_TO_CAREGIVER]: importantToCaregiver,
        [ResultType.IMPORTANT_TO_PATIENT]: importantToPatient,
        [ResultType.IMPORTANT_TO_BOTH]: importantToBoth,
        [ResultType.TOP_PRIORITY]: topResults,
        [ResultType.NON_RANKED_PARTNER]: nonRankedPartnerResults,
        [ResultType.NON_RANKED_PATIENT]: nonRankedPatientResults
    };
};

//format each result and combine into single array
export const adaptResultsToApi = (results: RankedPairResults): UpdateResultRequest[] => {
    const resultRequest: UpdateResultRequest[] = [];

    _.forIn(results, (resultArray, key) => {
        const formattedResults = resultArray.map(result => {
            return {
                questionId: result.id,
                topPriority: key === ResultType.TOP_PRIORITY.toString(),
            };
        });
        resultRequest.push(...formattedResults);
    });

    return resultRequest;
};
